import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { DomainService } from '../../domain.service';

@Injectable({
  providedIn: 'root'
})
export class GeocercaService {

  constructor(private http: HttpClient, private dom: DomainService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
    //Authorization: this.authService.getToken()
  })

  headers2: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/ms-excel'
  })

  url: string = this.dom.getDomain()



  //Rutas api/metallic/geocerca_by_provider/0100779788/
  getRoutes(): Observable<any[]> {
    const url_api = this.url + 'api/metallic/geocerca_list/'    //'api/metallic/geocerca/' 
    
    return this.http.get<any[]>(url_api, { headers: this.headers });
  }
  getRouteById(id) {
    const url_api = this.url + `api/metallic/geocerca/${id}/`
    return this.http.get<any[]>(url_api, { headers: this.headers });
  }
  postRoute(data) {
    const url_api = this.url + 'api/metallic/geocerca/'
    return this.http.post(url_api, {
      TYPE : data.FILTER,
      DEPARTMENT : data.DEPARTMENT,
      PROVINCE : data.PROVINCE,
      DISTRICT: data.DISTRICT,
      PROVIDER:data.PROVIDER ,

    }, { headers: this.headers });
  }

  putRoute(data, id) {
    const url_api = this.url + `api/metallic/geocerca/${id}/`
    return this.http.put(url_api, 
        {
          TYPE : data.FILTER,
          DEPARTMENT : data.DEPARTMENT,
          PROVINCE : data.PROVINCE,
          DISTRICT: data.DISTRICT,
          PROVIDER:data.PROVIDER ,
    
        }
        
        , { headers: this.headers });
  }

  deleteRoute(id) { 
  
    const url_api = this.url + `api/metallic/geocerca/${id}/`
    return this.http.delete(url_api, { headers: this.headers });
  }

  // centros metalicos
  getComboCenter() {
    const url_api = this.dom.getDomain() + `api/metallic/cb/center/`
    return this.http.get(url_api, { headers: this.headers });
  }


  	//proveedores
	urlmetal: string = this.dom.getDomain() + 'api/main/cb/provider_metal_group/';

	getProvideersMetal(): Observable<any[]> {
		const url_api = this.urlmetal
		return this.http.get<any[]>(url_api, { headers: this.headers });
    }


    urlZonasDepartments:string = this.dom.getDomain() + 'api/main/load_zones/departments/';
    getAllLoadZonesDepartments(): Observable<any[]> {
      const url_api = this.urlZonasDepartments;
      return this.http.get<any[]>(url_api, { headers: this.headers });
    }

    urlZonasProvince:string = this.dom.getDomain() + 'api/main/load_zones/provinces_filter/';
    getAllLoadZonesProvince(data:any): Observable<any[]> {
      const url_api = `${this.urlZonasProvince}${data}/`;
      return this.http.get<any[]>(url_api, { headers: this.headers });
    }

   
    urlZonasDistrict:string = this.dom.getDomain() + 'api/main/load_zones/districts_filter/';
	getAllLoadZonesDistricts(data:any): Observable<any[]> {
		const url_api = `${this.urlZonasDistrict}${data}/`;
		return this.http.get<any[]>(url_api, { headers: this.headers });
	}




}