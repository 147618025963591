import { DomainService } from './../../../../services/domain.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private http: HttpClient, private dom: DomainService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
    //Authorization: this.authService.getToken()
  })

  //start transport

  //tumbril
  urltum: string = this.dom.getDomain() + 'api/main/item/'
  getItems(): Observable<any[]> {
    const url_api = this.urltum
    return this.http.get<any[]>(url_api, { headers: this.headers });
  }
  // paginacion
  getItemsByFilter(codes, name) {
    const url_api = this.urltum + 'pagination/' + codes + '/' + name + '/'
    return this.http.get(url_api, { headers: this.headers });
  }
  getItemsByFilterUrl(url) {
    const url_api = url
    //console.log('Esta es toda la url que envio: ', url_api)
    return this.http.get(url_api, { headers: this.headers });
  }




  addItem(data: any) {
    const url_api = this.urltum
    return this.http.post(url_api, data, { headers: this.headers });
  }
  updateItem(data: any) {
    const url_api = this.urltum + data.id + "/"
    return this.http.put(url_api, data, { headers: this.headers });
  }
  deleteItem(id: number) {
    const url_api = this.urltum + id + "/"
    return this.http.delete(url_api, { headers: this.headers });
  }
  getItemSelect() {
    const url_api = this.dom.getDomain + "api/main/cb/item/"
    return this.http.get(url_api, { headers: this.headers })
  }
  getItemByChannel(data) {
    const url_api = this.urltum + `get/sselect/canal/${data}/`
    return this.http.get(url_api, { headers: this.headers });
  }


  // asignacion

  asigSector(data) {
    const url_api = this.urltum + 'sector/'
    return this.http.post(url_api, data, { headers: this.headers });
  }
  asigHierarchy(data) {
    const url_api = this.urltum + 'hierarchy/'
    return this.http.post(url_api, data, { headers: this.headers });
  }

  //saveOrder 


  saveOrderItems(data) {
    const url_api = this.dom.getDomain() + 'api/main/product/hierarchy/position/'
    return this.http.post(url_api, data, { headers: this.headers });
  }

  // get sectores combos
  getSectorSelect() {
    const url_api = this.dom.getDomain() + "api/main/cb/sector/"
    return this.http.get(url_api, { headers: this.headers })
  }
  //get jerarquia de productos
  getHierarchySelect() {
    const url_api = this.dom.getDomain() + "api/main/master/hierarchy/"
    return this.http.get(url_api, { headers: this.headers })
  }


  activarMetalico (code) {
    const url_api = this.dom.getDomain() + 'api/main/metallic/item/' + code + '/'
    return this.http.get(url_api, { headers: this.headers })
  }

}