import { DomainService } from '../../../services/domain.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class EventsService {

	constructor(private http: HttpClient, private dom: DomainService) { }

	headers: HttpHeaders = new HttpHeaders({
		'Content-Type': 'application/json'
	});

	urltum: string = this.dom.getDomain() + 'api/configuration/';

	// Configuracion Eventos

	
	getAllConfigEvents() {
		
		const url_api = this.urltum + 'type_event/';
		return this.http.get(url_api, { headers: this.headers });
	}
	getConfigEventsByID(id) {
		const url_api = this.urltum + 'type_event/' + id + '/';
		return this.http.get(url_api, { headers: this.headers });
	}
	putUpdateConfigEvent(id, data) {
		const url_api = this.urltum + 'type_event/' + id + '/';
		return this.http.put(url_api, data, { headers: this.headers });
	}
	// Eventos
	getAllEvents() {
		const url_api = this.urltum + 'event/';
		return this.http.get(url_api, { headers: this.headers });
	}
	getAllEventsFilter(data){
		const url_api = this.urltum + 'event/filter/';
		return this.http.post(url_api, data, { headers: this.headers });
	}

	getEventByID(id) {
		const url_api = this.urltum + 'event/' + id + '/';
		return this.http.get(url_api, { headers: this.headers });
	}
	getlistAllTypeEvents() {
		const url_api = this.urltum + 'cb/type_event/';
		return this.http.get(url_api, { headers: this.headers });
	}
	postNewEvent(data) {
		const url_api = this.urltum + 'event/';
		return this.http.post(url_api, data,{ headers: this.headers });
	}
	putEditEvent(id, data) {
		const url_api = this.urltum + 'event/' + id + '/';
		return this.http.put(url_api, data,{ headers: this.headers });
	}
	deleteEvent(id) {
		const url_api = this.urltum + 'event/' + id + '/';
		return this.http.delete(url_api,{ headers: this.headers });
	}
	// getConfCorreos () {
	// 	const url_api = this.urltum + 'configuration/type_mail/'
	// 	return this.http.get(url_api, { headers: this.headers });
	// }

	// saveConfCorreos (data) {
	// 	const url_api = this.urltum + 'configuration/mail/'
	// 	return this.http.post(url_api,data, { headers: this.headers });
	// }


}
