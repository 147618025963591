import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { DomainService } from '../../domain.service';

@Injectable({
  providedIn: 'root'
})
export class AccountStatusService {

  constructor(private http: HttpClient, private dom: DomainService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  })
  url: string = this.dom.getDomain() + 'api/'

  getAllAccountStatus(data) {
    const url_api = this.url + 'main/customer/open_report/'
    return this.http.post(url_api, data, { headers: this.headers });
  }

  getAllCustomer() {
    const url_api = this.url + 'main/cb/customer/'
    return this.http.get(url_api, { headers: this.headers });
  }


  getExcelAccountStatus(model: any): Observable<any> {

    var userLocalToken = JSON.parse(localStorage.getItem('currentUser')).token
    console.log('Este es el token: ', userLocalToken)

    return new Observable(obs => {
      var oReq = new XMLHttpRequest();
      const url_api = this.url + 'main/customer/open_report/export/'

      oReq.open("POST", url_api, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.setRequestHeader("Authorization", "Token " + userLocalToken);
      oReq.responseType = "arraybuffer";


      oReq.onload = function (oEvent) {
        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };

      const body = JSON.stringify(model);
      console.log('xlsssstumbril', body)

      oReq.send(body);
    });
  }


}