//center type
export class CenterType{
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public description: string,
    public status: string
  ){}
}
//center owner
export class CenterOwner{
  constructor(
    public id?:number,
    public code?:string,
    public name?:string,
    public ruc?:string,
    public contact?:string,
    public phoneContact?:string,
    public mailContact?:string,
    public company?:string,
    public status?:string,
  ){
  }
}
// center
export class Center{
  constructor(
    public id?:number,
    public code?:string,
    public location?:string,
    public codeType?:string,
    public address?:string,
    public latitude?:string,
    public longitude?:string,
    public timeAppend?:string,
    public timeEnd ?: string,
    public codeClient?: string,
    public localOwner?:string,
    public status?:string
  ){
  }
}

export class Center1 {
  constructor(
    public id?:number,
    public CODE?:string,
    public location?:string,
    public codeType?:string,
    public address?:string,
    public latitude?:string,
    public longitude?:string,
    public timeAppend?:string,
    public timeEnd ?: string,
    public codeClient?: string,
    public localOwner?:string,
    public status?:string
  ){}
}

// cwarehouse
export class WareHouse{
  constructor(
    public id?:number,
    public code?:string,
    public codeLocal?:string,
    public name?:string,
    public address?:string,
    public latitude?:string,
    public longitude?:string,
    public timeAppend?:string,
    public timeEnd ?: string,
    public status?:string
  ){
  }
}

// door
export class Door{
  constructor(
    public id ?: number,
    public CODE ?: string, 
    public WAREHOUSECODE ?:string,
    public DESCRIPTION ?:string,
    public LATITUDE ?: string,
    public LONGITUDE ?:string,
    public RADIO?:string,
    public PHONE ?: string,
    public CONTACT?: string,
    public ACTIVE?: boolean
  ){
  }
}